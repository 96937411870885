import Vue from 'vue'
import store from './store'
import axios from 'axios'
import { Message } from 'element-ui';

// 引入elementui
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
// 引入echarts
// import * as echarts from 'echarts';
// 引入粒子动效
// import VueParticles from 'vue-particles'
// Vue.use(VueParticles)
// 引入优化message
import { ElMessage } from '@/assets/js/elementui-api'
Vue.prototype.$message = new ElMessage()

Vue.prototype.$axios = axios
axios.defaults.baseURL = '/api'  //关键代码
Vue.config.productionTip = false
import router from './router'
import App from './App.vue'
// 引入iconfont.css
import "@/assets/fonts/iconfont.css"
// 引入reset.css
import "@/assets/css/reset.css"

// 响应拦截  判断token失效跳转
axios.interceptors.response.use(response => {
  let Message = new ElMessage()
  // console.log(response.data)
  if (response.status == '500') {
    // localStorage.removeItem('TOKEN')
    this.$router.push('/login')
    console.log(response.data.message);
  } else
    if (response.data.statusCode == '51000') {
      // alert("登录失效，请重新登录!")
      Message.warning("TOKEN失效,请重新登录");
      localStorage.removeItem('TOKEN');
      router.push('/login');
    }
  return response;
}, error => {
  Message.warning("当前网络不稳定,请稍后再试");
  router.push('/home');
  return Promise.reject(error)//返回接口返回的错误信息
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
