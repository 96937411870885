<template>
  <div class="layout">
    <!-- 左侧菜单 -->
    <left-menu class="left"></left-menu>

    <!-- 右侧主体内容 -->
    <div class="right-main">
      <right-header></right-header>

      <div class="content">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
// 引入组件
import LeftMenu from "./LeftMenu.vue";
import RightHeader from "./RightHeader.vue";

export default {
  // 注册
  components: {
    LeftMenu,
    RightHeader,
  },
};
</script>

<style lang="less" scoped>
.layout {
  display: flex;
  height: 100%;
  .left {
    overflow: auto;
    // width: 230px !important;
  }
  .right-main {
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: #f0f2f5;
    width: 0;
    // overflow: hidden;
    // height: 100%;
    .content {
      padding: 10px;
      flex: 1;
      overflow-x: hidden;
      overflow-y: scroll;
    }
  }
}
</style>