<template>
  <div class="right-header">
    <el-row>
      <!-- 左侧 -->
      <el-col :md="12" :sm="16" :xs="14">
        <!-- 面包屑导航 -->
        <!-- <el-breadcrumb>
          <el-breadcrumb-item
            v-for="v in breads"
            :key="v.title"
            :to="{ path: v.path }"
          >
            {{ v.title }}
          </el-breadcrumb-item>
        </el-breadcrumb> -->
      </el-col>

      <!-- 右侧 -->
      <el-col class="right" :md="12" :sm="8" :xs="10">
        <!-- 待审核工单 -->
        <!-- <el-button @click="onHint" style="border: 0px" v-if="hintMessage">
          <el-badge is-dot style="font-size: 14px">待审核工单</el-badge>
        </el-button> -->
        <!-- <div style="margin-right: 150px; font-size: 14px">
          客服电话: 028-88122222
        </div> -->

        <!-- 拨号 -->
        <el-button
          class="call"
          type="primary"
          icon="el-icon-phone"
          v-if="roleName == '人员' || roleName == '组长'"
          @click="oneCallNum"
          >拨打</el-button>
        <!-- SIP呼叫 -->
        <!-- <el-button
          v-if="roleName == '人员' || roleName == '组长'"
          @click="onSip"
          style="border: 0px"
          >SIP呼叫</el-button
        > -->
        <!-- 探客躺赚 -->
        <!-- <el-button @click="onTktz" style="border: 0px; margin-left: 0px">
          探客躺赚
        </el-button> -->

        <!-- 下拉 -->
        <el-dropdown @command="handleCommand" style="cursor: pointer;;">
          <span class="el-dropdown-link" style="color: #fff">
            欢迎你，{{ realname }}
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <!-- <el-dropdown-item
              command="sip"
              v-if="roleName == '人员' || roleName == '组长'"
              >SIP呼叫</el-dropdown-item
            > -->
            <!-- <el-dropdown-item command="sysc">使用手册</el-dropdown-item> -->
            <!-- <el-dropdown-item command="htpz" v-if="roleName == '企业'"
              >后台配置</el-dropdown-item
            > -->
            <el-dropdown-item command="logout">退出系统</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>

        <!-- 头像 -->
        <!-- <el-avatar :size="50" :src="avatarUrl"></el-avatar> -->
      </el-col>
    </el-row>
    <!-- 拨打按钮弹出框 -->
    <el-dialog title="请输入号码" :visible.sync="onecallVisible" width="30%">
      <el-form ref="form" :model="form" label-width="100px">
        <el-form-item label="请输入号码">
          <el-input v-model="callNum" placeholder="请输入号码"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="onecallVisible = false">取 消</el-button>
          <el-button type="primary" @click="onecall">拨 打</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- sip弹出框 -->
    <el-dialog
      title="SIP呼叫"
      :visible.sync="sipCallVisible"
      width="30%"
      :before-close="handleClose"
      @close="hang_up"
    >
      <sipCall
        :callPhoneNum="callNum"
        :callPhoneData="callData"
        @showValue="showMethod"
      ></sipCall>
    </el-dialog>
  </div>
</template>

<script>
import sipCall from "@/views/sip/sipCall.vue";
import { ElMessage } from "@/assets/js/elementui-api";
let Message = new ElMessage();
// 引入ajax函数
import { callAppBind } from "@/api/call";
import { workOrderRemindWorkType,getLoginOut } from "@/api/workOrder";
import local from "@/utils/local";

export default {
  components: { sipCall },

  data() {
    return {
      ua: "",
      callData: "", // 拨号后接口数据
      sipCallVisible: false,
      realname: "小阿晴", // 用户名
      roleName: null,
      // 默认头像
      avatarUrl: "http://localhost:8080/img/shangjiyun.d50877f3.png",
      // 面包屑数组
      breads: [],
      tktz: "http://tktz.cddmt.cn/",
      sysc: "http://113.250.189.239:9005/khd.pdf",
      callNum: null,
      onecallVisible: false,
      hintMessage: "",
      form: {},
    };
  },
  created() {
    this.calcBreads(); // 进入页面或刷新 都需要计算面包屑
    this.realname = localStorage.getItem("realname");
    this.roleName = localStorage.getItem("roleName");
  },
  watch: {
    "$route.path"() {
      this.calcBreads(); // 切换导航 路由地址变化 也要计算面包屑/
    },
  },
  mounted() {
    this.hintList();
  },
  methods: {
    // 挂断
    hang_up() {
      if (this.ua) {
        this.ua.stop();
      }
    },
    // sip挂断关闭弹框
    showMethod(obj) {
      this.sipCallVisible = obj.bool;
      this.ua = obj.ua;
    },
    // 编辑关闭回调
    handleClose(done) {
      this.$confirm("如需关闭弹框，请挂断SIP通话")
        .then((_) => {
          // done();
        })
        .catch((_) => {});
    },
    onSip() {
      this.$router.push("/sip");
    },
    onTktz() {
      window.open(this.tktz, "_blank");
    },
    // 待审核工单提示
    hintList() {
      let params = {};
      let res = workOrderRemindWorkType(params);
      res.then((res) => {
        this.hintMessage = res.data.data;
      });
    },
    // 跳转到待审核工单
    onHint() {
      this.$router.push("/workorder/notCheckWorkorder");
    },
    // 拨打按钮
    oneCallNum() {
      this.callNum = "";
      this.onecallVisible = true;
    },
    // 拨打确定按钮
    onecall() {
      console.log("页面的号码", this.callNum);
      this.onecallVisible = false;
      let parentId = JSON.parse(localStorage.getItem("parentId"));
      let activePhone = JSON.parse(localStorage.getItem("ms_username"));
      let params = {
        passivePhone: this.callNum,
        activePhone,
        parentId,
      };
      let res = callAppBind(params);
      res.then((res) => {
        this.callData = res;
        console.log(" this.callData", this.callData);
        if (res.data.statusCode == "00000") {
          // Message.success({
          //   showClose: true,
          //   message: res.data,
          //   duration: 0,
          // });
          Message.success(res.data);

          // console.log(1234567, res.data.data.sip);
          //判断是否是AXB呼叫模式
          if (res.data.data.sip) {
            // console.log("sipsip");
            this.sipCallVisible = true;
            // this.callNum;
          } else if (res.data != null && !res.data.data.sip) {
            // console.log(res.data.data);
            this.xphone = res.data.data;
            this.callVisible = true;
            this.countTime();
          } else {
            Message.success("请注意接听手机电话！");
          }
        } else {
          let data = res.data.data;
          if (data) {
            // this.$message.error(JSON.parse(data).message);
            // Message.success({
            //   showClose: true,
            //   message: JSON.parse(data).message,
            //   duration: 0,
            // });
            Message.success(JSON.parse(data).message);
            // this.$message.error((JSON.parse(data).message) || (JSON.parse(data).msg));
          } else {
            // Message.success({
            //   showClose: true,
            //   message: res.data,
            //   duration: 0,
            // });
            Message.success(res.data);

          }
        }
      });
    },
    // 计算面包屑
    calcBreads() {
      let temp = [{ path: "/", title: "首页" }];

      // 计算出当前面包屑 循环匹配到的路由
      this.$route.matched.forEach((v) => {
        // 如果匹配到的路由meta中配置了path 和 title
        if (v.meta.path && v.meta.title) {
          // 就放在一个对象中，且 把对象push进入temp里面
          temp.push({ path: v.meta.path, title: v.meta.title });
        }
      });

      // 把结果赋值渲染
      this.breads = temp;
    },
    // 点击菜单项
    handleCommand(command) {
      // 使用手册
      if (command === "sysc") {
        window.open(this.sysc, "_blank");
      }
      // 后台配置
      if (command === "htpz") {
        // this.$router.push("/login");
      }
      // 退出登录
      if (command === "logout") {
        // 同时调用退出接口
        let params = {};
        let res = getLoginOut(params);
        local.clear(); // 清除本地数据

        // 弹出提示
        Message.success("退出成功");

        // 跳转到登录
        this.$router.push("/login");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.right-header {
  padding: 0 20px;
  height: 60px;
  // background-color: #fff;
  color: #fff;
  background-color: #1F7EFE;
  box-shadow: 0px 0px 1px #ccc;
  .el-button {
    margin-right: 10px;
  }
  .el-col {
    height: 60px;
    display: flex;
    align-items: center;
    .el-avatar {
      margin-left: 5px;
    }
  }
  .right {
    justify-content: flex-end;
    .call {
      height: 40px;
      width: 100px;
      // margin-left: 30px;
      // margin-right: 30px;
      background-image: linear-gradient(to right, #e0e0e0, #7ba6dc);
      border: 0;
      border-radius: 5px;
    }
  }
}
</style>

