/**
 * workOrder
 * 工单
 */

import request from '@/utils/request'

// 
export const workOrderLikeSearch = (data) => {
    return request({
        method: 'post',
        url: '/workOrder/likeSearch',
        data
    })
}

// 退出登录
export const getLoginOut = (data) => {
    return request({
        method: 'post',
        url: '/login/out',
        data
    })
}
// 
export const workOrderUpdateWorkUserOrderType = (data) => {
    return request({
        method: 'post',
        url: '/workOrder/updateWorkUserOrderType',
        data
    })
}
// 
export const workOrderSelectWorkUserOrderType = (data) => {
    return request({
        method: 'post',
        url: '/workOrder/selectWorkUserOrderType',
        data
    })
}
// 
export const workOrderSelectWorkOrderTypeCopy = (data) => {
    return request({
        method: 'post',
        url: '/workOrder/selectWorkOrderTypeCopy',
        data
    })
}
// 
export const workOrderUpdateWorkOrderType = (data) => {
    return request({
        method: 'post',
        url: '/workOrder/updateWorkOrderType',
        data
    })
}
// 
export const workOrderAddWorkUserOrderType = (data) => {
    return request({
        method: 'post',
        url: '/workOrder/addWorkUserOrderType',
        data
    })
}
// 
export const workOrderDeleteWorkOrderType = (data) => {
    return request({
        method: 'post',
        url: '/workOrder/deleteWorkOrderType',
        data
    })
}
// 
export const workOrderAddWorkOrderType = (data) => {
    return request({
        method: 'post',
        url: '/workOrder/addWorkOrderType',
        data
    })
}
// 
export const workOrderRemindWorkType = (data) => {
    return request({
        method: 'post',
        url: '/workOrder/remindWorkType',
        data
    })
}
// 
export const workOrderParticipantWorkOrder = (data) => {
    return request({
        method: 'post',
        url: '/workOrder/participantWorkOrder',
        data
    })
}
// 
export const workOrderCopySee = (data) => {
    return request({
        method: 'post',
        url: '/workOrder/copySee',
        data
    })
}
// 发送短信
export const workOrderSend = (data) => {
    return request({
        method: 'post',
        url: '/workOrder/send',
        data
    })
}