<template>
  <div id="dmtapp">
    <!-- <vue-particles
      id="particles-js"
      class="lizi"
      color="#4AB4FF"
      :particleOpacity="0.7"
      :particlesNumber="150"
      shapeType="circle"
      :particleSize="2"
      linesColor="#4AB4FF"
      :linesWidth="1"
      :lineLinked="true"
      :lineOpacity="0.4"
      :linesDistance="150"
      :moveSpeed="2"
      :hoverEffect="true"
      hoverMode="grab"
      :clickEffect="false"
      clickMode="push"
    >
    </vue-particles> -->
    <!-- <router-view v-if="isRouterAlive"></router-view> -->
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  //         ┌─┐       ┌─┐
  //      ┌──┘ ┴───────┘ ┴──┐
  //      │                 │
  //      │       ───       │
  //      │  ─┬┘       └┬─  │
  //      │                 │
  //      │       ─┴─       │
  //      │                 │
  //      └───┐         ┌───┘
  //          │         │
  //          │         │
  //          │         │
  //          │         └──────────────┐
  //          │                        │
  //          │                        ├─┐
  //          │                        ┌─┘
  //          │                        │
  //          └─┐  ┐  ┌───────┬──┐  ┌──┘
  //            │ ─┤ ─┤       │ ─┤ ─┤
  //            └──┴──┘       └──┴──┘
  //                神兽保佑
  //                代码无BUG!

  data() {
    return {
      isRouterAlive: true,
    };
  },
  provide() {
    //提供
    return {
      reload: this.reload,
    };
  },
  created() {
    console.log("新的网页");
  },
  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },
  },
};
</script>

<style lang="less" scoped>
#dmtapp {
  height: 100%;
  position: relative;

  #particles-js {
    position: fixed;
    top: 0px;
    left: 0px;
    opacity: 0.6;
  }

  .lizi {
    width: 12.5%;
    height: 100%;
  }
}
</style>