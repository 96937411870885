<template>
  <div class="login">
    <div class="box">
      <div class="login_form">
        <div class="form">
          <div class="ms-title">123呼后台管理系统登录</div>
         
          <!-- <div class="head">
            <span
              :class="tabType == 'yzm' ? 'tab_active' : ''"
              @click="changeTab('username', true)"
              >账号密码登录</span
            >
            <img src="" alt="" />
            <span
              :class="tabType == 'username' ? 'tab_active' : ''"
              @click="changeTab('yzm', true)"
              >验证码登录</span
            >
          </div> -->
          <el-form
            :model="param"
            :rules="rules"
            ref="param"
            class="param"
            v-if="tabType == 'username'"
          >
            <el-form-item prop="username">
              <el-input
                v-model="param.username"
                placeholder="账号"
                prefix-icon="el-icon-mobile-phone"
                @input="getUserName"
              ></el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input
                v-model="param.password"
                placeholder="密码"
                prefix-icon="el-icon-lock"
                @input="getPwd"
                show-password
              ></el-input>
            </el-form-item>

            <el-form-item prop="identity" class="identity">
              <el-input
                v-model="identity"
                prefix-icon="el-icon-tickets"
                placeholder="验证码 "
                style="margin-right: 10px"
                @input="getUserCode"
              ></el-input>
              <div @click="handleRefreshCode()">
                <Identify :identifyCode="identifyCode"></Identify>
              </div>
            </el-form-item>
          </el-form>
          <el-form
            :model="param"
            :rules="phoneRules"
            ref="param"
            class="param"
            v-if="tabType == 'yzm'"
          >
            <el-form-item prop="username">
              <el-input
                v-model="param.username"
                placeholder="请输入手机号"
                prefix-icon="el-icon-mobile-phone"
                maxlength="11"
                @input="phoneBtn"
              ></el-input>
            </el-form-item>
            <el-form-item prop="msgCode" class="time">
              <el-input
                v-model="param.code"
                placeholder="验证码"
                prefix-icon="el-icon-tickets"
                @input="logCode(param.code)"
              ></el-input>
              <span v-show="!show" class="count" style="color: red"
                >{{ count }} s</span
              >
              <el-button
                class="yzm"
                type="primary"
                plain
                @click="getLoginCode"
                v-show="show"
                >获取验证码</el-button
              >
            </el-form-item>

            <el-form-item prop="identity" class="identity">
              <el-input
                v-model="identityB"
                prefix-icon="el-icon-tickets"
                placeholder="验证码"
                style="margin-right: 10px"
                @input="getUserCodeB"
              ></el-input>
              <div @click="handleRefreshCodeB()">
                <Identify :identifyCode="identifyCodeB"></Identify>
              </div>
            </el-form-item>
          </el-form>
          <div class="btns">
            <el-button
              type="primary"
              @click="submitForm"
              :disabled="identityBol"
              >立即登录</el-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ElMessage } from "@/assets/js/elementui-api";
let Message = new ElMessage();
import { getLogin, getCode } from "@/api/login";
import { findEncryption } from "@/api/phone-encryption";
import Identify from "./modules/Identity.vue";
export default {
  name: "login",
  components: { Identify },
  data() {
    return {
      identityBol: true,
      identity: "",
      identityB: "",
      identifyCode: "", // 验证码初始值
      identifyCodeB: "", // 验证码初始值
      identifyCodes: "1234567890", // 验证码的随机取值范围
      show: true,
      count: "",
      timer: null,
      tabType: "username",
      code: "",
      realname: null,
      rules: {
        username: [
          { required: true, message: "请输入手机号", trigger: "blur" },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        msgCode: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      param: {
        username: null,
        password: null,
        code: null,
      },
      rules: {
        username: [{ required: true, message: "请输入账号", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
      phoneRules: {
        username: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          { min: 11, max: 11, message: "长度应为11位", trigger: "blur" },
        ],
        msgCode: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      drawer: false,
      regForm: {
        username: "",
        email: "",
        pass: "",
        checkPass: "",
        companyName: "",
        realname: "",
      },
    };
  },
  created() {
    this.$store.commit("clearTags");
  },
  mounted() {
    this.handleRefreshCode();
    this.handleRefreshCodeB();
  },
  methods: {
    // 输入验证码
    // 账户登录
    getUserCode() {
      if (
        this.param.username != "" &&
        this.param.password != "" &&
        this.identity == this.identifyCode
      ) {
        this.identityBol = false;
      } else {
        this.identityBol = true;
      }
    },
    // 账户输入
    getUserName() {
      if (
        this.param.username != "" &&
        this.param.password != "" &&
        this.identity == this.identifyCode
      ) {
        this.identityBol = false;
      } else {
        this.identityBol = true;
      }
    },
    // 密码输入
    getPwd() {
      if (
        this.param.username != "" &&
        this.param.password != "" &&
        this.identity == this.identifyCode
      ) {
        this.identityBol = false;
      } else {
        this.identityBol = true;
      }
    },
    // 验证码登录
    getUserCodeB() {
      if (
        this.param.username.length == 11 &&
        this.param.code != "" &&
        this.identifyCodeB == this.identityB
      ) {
        this.identityBol = false;
      } else {
        this.identityBol = true;
      }
    },
    // 刷新验证码
    handleRefreshCode() {
      this.identifyCode = "";
      this.makeCode(this.identifyCodes, 4);
    },
    handleRefreshCodeB() {
      this.identifyCodeB = "";
      this.makeCodeB(this.identifyCodes, 4);
    },
    // 生成一个随机整数  randomNum(0, 10) 0 到 10 的随机整数， 包含 0 和 10
    randomNum(min, max) {
      max = max + 1;
      return Math.floor(Math.random() * (max - min) + min);
    },
    // 随机生成验证码字符串
    makeCode(data, len) {
      for (let i = 0; i < len; i++) {
        this.identifyCode += data[this.randomNum(0, data.length - 1)];
      }
      console.log("这是验证码", this.identifyCode);
    },
    makeCodeB(data, len) {
      for (let i = 0; i < len; i++) {
        this.identifyCodeB += data[this.randomNum(0, data.length - 1)];
      }
      console.log("这是验证码", this.identifyCode);
    },

    phoneBtn() {
      if (
        this.param.username.length == 11 &&
        this.param.code != "" &&
        this.identityB == this.identifyCodeB
      ) {
        this.identityBol = false;
      } else {
        this.identityBol = true;
      }
    },
    logCode(v) {
      if (
        this.param.username.length == 11 &&
        this.param.code != "" &&
        this.identityB == this.identifyCodeB
      ) {
        this.identityBol = false;
      } else {
        this.identityBol = true;
      }

      console.log("打印验证码登录", this.identityBol);
    },
    changeTab(type, bol) {
      this.identity = "";
      this.identityBol = bol;
      this.tabType = type;
      this.param.username = "";
      this.param.code = "";
      this.param.password = "";
    },
    openModle() {
      this.drawer = true;
    },
    getHideNum() {
      let params = {};
      let res = findEncryption(params);
      res.then((res) => {
        localStorage.setItem("hideNum", res.data.data);
      });
    },
    async loginApi(params) {
      let res = await getLogin(params);
      if (res.data.statusCode == "00000") {
        Message.success("登录成功");
        localStorage.setItem("ms_username", this.param.username);
        localStorage.setItem("id", res.data.data.user.id);
        localStorage.setItem("realname", res.data.data.user.realname);
        localStorage.setItem("TOKEN", res.data.data.token);
        localStorage.setItem("parentId", res.data.data.user.parentId);
        localStorage.setItem("roleName", res.data.data.roleName);
        localStorage.setItem("role", res.data.data.user.remark);

        if (res.data.data.roleName != "企业") {
          console.log("查询隐藏号码");
          this.getHideNum();
        }
        localStorage.setItem(
          "Permission",
          JSON.stringify(res.data.data.Permission)
        );
        this.$router.push("/");
        localStorage.setItem(
          "encryption",
          JSON.stringify(res.data.data.Permission.indexOf("encryption"))
        );
      } else if (res.data.statusCode != "00000") {
        Message.error(res.data.message);
        // return false;
      } else {
        // Message.error("大迈通提醒您等待2分钟稍后重试哦!");
      }
    },
    submitForm() {
        let lastIp = sessionStorage.getItem("ip");
      let params = {
        username: this.param.username,
        password: this.param.password,
        code: this.param.code,
        ipAddress :lastIp
      };
      if (this.param.username == "" || this.param.username == null) {
        this.loginApi(params);
      } else {
        this.loginApi(params);
      }
      // 点击登录后清除部分数据
      this.param.password = "";
      this.param.code = "";
      this.identity = "";
      this.identityB = "";
    },
    //获取验证码
    getLoginCode() {
      if (this.param.username != null && this.param.username != "") {
        const TIME_COUNT = 60;
        if (!this.timer) {
          this.count = TIME_COUNT; // 这些参数还没定义
          this.show = false;
          this.timer = setInterval(() => {
            if (this.count > 0 && this.count <= TIME_COUNT) {
              this.count--;
            } else {
              this.show = true;
              clearInterval(this.timer);
              this.timer = null;
            }
          }, 1000);
        }

        let params = {
          username: this.param.username,
        };
        let res = getCode(params);
        res.then((res) => {
          if (res.data.statusCode == "00000") {
            Message.success(res.data.message);
          } else {
            Message.error(res.data.message);
          }
        });
      } else {
        Message.error("请输入手机号！");
      }
    },
  },
};
</script>

<style lang='less' scoped>
.identity {
  display: flex;
  align-items: center;
}
.login {
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url(../../assets/images/bg3.png);
  background-size: 100%;
}
.param /deep/ .el-input__icon {
  height: 0;
}
.ms-title {
  /* width: 100%; */
  line-height: 50px;
  font-weight: bold;
  text-align: center;
  font-size: 20px;
  color: rgb(63, 210, 239);
  /* border-bottom: 1px solid #ddd; */
}
.box {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.login_form {
  border-radius: 5px;
  background: rgba(226, 242, 255, 0.5);
  width: 380px;
  /* height: 380px; */
  padding: 15px;
  margin-right: 12%;
  margin-top: 16%;
}
.form {
  background: #fff;
  border-radius: 5px;
  /* height: 380px; */
  padding-bottom: 20px;
  box-sizing: border-box;
}
.head {
  width: 100%;
  height: 50px;
  display: flex;
  border-radius: 5px 5px 0 0;
}
.head > span {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  background: #409eff;
  color: #fff;
  cursor: pointer;
}
.head > span:nth-child(1) {
  border-radius: 5px 0 0 0;
}
.head > span:nth-child(2) {
  border-radius: 0 5px 0 0;
}
.head > .tab_active {
  color: #409eff;
  background-color: #fff;
}
.yzm {
  margin-left: 20px;
}
.count {
  margin-left: 20px;
  width: 112px;
  height: 40px;
}
.param {
  padding: 20px 20px 0 20px;
}
.param /deep/ .el-form-item__content {
  margin-left: 0 !important;
}
.param {
  padding: 20px 20px 0 20px;
}
.param /deep/ .el-form-item__content {
  margin-left: 0 !important;
  display: flex;
  /* align-items: center; */
}
.param /deep/ .el-form-item__content /deep/ .el-input:nth-child(2) {
  width: 60%;
}
.param /deep/ .el-form-item__content /deep/ .el-button {
  margin-left: 25px;
}
p {
  color: #409eff;
  font-size: 14px;
  padding: 0 20px;
  cursor: pointer;
  width: 130px;
  margin-top: 30px;
}
.btns {
  padding: 0 20px;
  margin-top: 10px;
  /* width: 100% !important; */
  justify-content: space-around;
}
.btns /deep/ .el-button {
  width: 100%;
}
</style>
