var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"left-menu"},[_vm._m(0),_c('el-menu',{attrs:{"background-color":"#fff","text-color":"#333","default-active":_vm.$route.path,"unique-opened":"","router":""}},[_c('el-menu-item',{attrs:{"index":"/home"}},[_c('i',{staticClass:"iconfont el-icon-s-home"}),_c('span',{attrs:{"slot":"title"},slot:"title"},[_vm._v("后台首页")])]),(
        (_vm.roleName != '人员' && _vm.roleName != '组长') ||
        _vm.parentId == 7 ||
        _vm.parentId == 3555 ||
        _vm.parentId == 2
      )?_c('el-submenu',{attrs:{"index":"0"}},[_c('template',{slot:"title"},[_c('i',{staticClass:"iconfont el-icon-s-platform"}),_c('span',[_vm._v("坐席管理")])]),(_vm.roleName != '企业' && _vm.parentId != 7 && _vm.parentId != 2)?_c('el-menu-item',{attrs:{"index":"/creatCompany/creatCompany"}},[_vm._v("公司管理")]):_vm._e(),(_vm.parentId == 2)?_c('el-menu-item',{attrs:{"index":"/creatCompany/creatCompanyService"}},[_vm._v("公司管理--内部")]):_vm._e(),(_vm.parentId != 7 && _vm.parentId != 2)?_c('el-menu-item',{attrs:{"index":"/creatCompany/creatSeat"}},[_vm._v("坐席管理")]):_vm._e(),(_vm.parentId == 2)?_c('el-menu-item',{attrs:{"index":"/creatCompany/creatSeatService"}},[_vm._v("坐席管理--内部")]):_vm._e()],2):_vm._e(),(_vm.roleName == '企业')?_c('el-submenu',{attrs:{"index":"1"}},[_c('template',{slot:"title"},[_c('i',{staticClass:"iconfont el-icon-s-check"}),_c('span',[_vm._v("组织架构")])]),_c('el-menu-item',{attrs:{"index":"/structure/department"}},[_vm._v("部门管理")]),_c('el-menu-item',{attrs:{"index":"/structure/authority"}},[_vm._v("权限管理")])],2):_vm._e(),_c('el-menu-item',{attrs:{"index":"/callLog"}},[_c('i',{staticClass:"iconfont el-icon-phone"}),_c('span',{attrs:{"slot":"title"},slot:"title"},[_vm._v("通话记录")])]),(
        (_vm.roleName != '人员' && _vm.roleName != '组长') ||
        _vm.parentId == 3555 ||
        _vm.role == '客服' ||
        _vm.parentId == 2
      )?_c('el-submenu',{attrs:{"index":"2"}},[_c('template',{slot:"title"},[_c('i',{staticClass:"iconfont el-icon-s-finance"}),_c('span',[_vm._v("财务管理")])]),_c('el-menu-item',{attrs:{"index":"/finance/changeLog"}},[_vm._v("坐席日志信息")])],2):_vm._e(),(_vm.parentId == 2)?_c('el-submenu',{attrs:{"index":"3"}},[_c('template',{slot:"title"},[_c('i',{staticClass:"iconfont el-icon-s-custom"}),_c('span',[_vm._v("客服管理")])]),_c('el-menu-item',{attrs:{"index":"/service/pending"}},[_vm._v("待处理续费查询")]),_c('el-menu-item',{attrs:{"index":"/service/processed"}},[_vm._v("已处理续费查询")]),(_vm.roleName == '管理员')?_c('el-menu-item',{attrs:{"index":"/service/practical"}},[_vm._v("查询实际续费金额")]):_vm._e(),(_vm.roleName == '管理员')?_c('el-menu-item',{attrs:{"index":"/service/lastMonth"}},[_vm._v("查询上月客服续费")]):_vm._e()],2):_vm._e(),(_vm.realname != 'admin')?_c('el-submenu',{attrs:{"index":"4"}},[_c('template',{slot:"title"},[_c('i',{staticClass:"iconfont el-icon-s-custom"}),_c('span',[_vm._v("客户管理")])]),(
          _vm.roleName != '管理员' && _vm.roleName != '组长' && _vm.roleName != '人员'
        )?_c('el-menu-item',{attrs:{"index":"/client/encryptSwitch"}},[_vm._v("号码隐私开关")]):_vm._e(),_c('el-menu-item',{attrs:{"index":"/client/clientNums"}},[_vm._v("客户列表")]),(_vm.roleName != '管理员')?_c('el-menu-item',{attrs:{"index":"/client/creatNums"}},[_vm._v("个人私海")]):_vm._e(),(_vm.roleName != '管理员')?_c('el-menu-item',{attrs:{"index":"/client/departNums"}},[_vm._v("部门公海")]):_vm._e(),(_vm.roleName != '管理员')?_c('el-menu-item',{attrs:{"index":"/client/companyNums"}},[_vm._v("公司公海")]):_vm._e(),(_vm.roleName != '组长' && _vm.roleName != '人员')?_c('el-menu-item',{attrs:{"index":"/client/evictionPolicy"}},[_vm._v("回收策略")]):_vm._e(),(
          _vm.roleName != '管理员' && _vm.roleName != '组长' && _vm.roleName != '人员'
        )?_c('el-menu-item',{attrs:{"index":"/client/recycle"}},[_vm._v("回收站")]):_vm._e()],2):_vm._e(),(_vm.roleName != '管理员')?_c('el-menu-item',{attrs:{"index":"/callMission"}},[_c('i',{staticClass:"iconfont el-icon-s-flag"}),_c('span',{attrs:{"slot":"title"},slot:"title"},[_vm._v("呼叫任务")])]):_vm._e(),_c('el-submenu',{attrs:{"index":"5"}},[_c('template',{slot:"title"},[_c('i',{staticClass:"iconfont el-icon-s-order"}),_c('span',[_vm._v("话务报表")])]),(_vm.roleName != '管理员')?_c('el-menu-item',{attrs:{"index":"/report/realReport"}},[_vm._v("实时报表")]):_vm._e(),(_vm.roleName != '企业' && _vm.roleName != '组长' && _vm.roleName != '人员')?_c('el-menu-item',{attrs:{"index":"/report/dayReport"}},[_vm._v("日报表")]):_vm._e(),(_vm.roleName != '企业' && _vm.roleName != '组长' && _vm.roleName != '人员')?_c('el-menu-item',{attrs:{"index":"/report/monthReport"}},[_vm._v("月报表")]):_vm._e()],2),(_vm.roleName != '管理员')?_c('el-menu-item',{attrs:{"index":"/repository"}},[_c('i',{staticClass:"iconfont el-icon-s-management"}),_c('span',{attrs:{"slot":"title"},slot:"title"},[_vm._v("知识库")])]):_vm._e(),_c('el-menu-item',{attrs:{"index":"/unmark"}},[_c('i',{staticClass:"iconfont el-icon-warning"}),_c('span',{attrs:{"slot":"title"},slot:"title"},[_vm._v("取消标记")])])],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"logo-container"},[_c('div',{staticClass:"logo",staticStyle:{"color":"#409eff","font-size":"18px","font-weight":"bold","margin-left":"20px"}},[_vm._v(" 123呼 - 专业外呼 ")])])}]

export { render, staticRenderFns }