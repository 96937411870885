import Vue from 'vue'
import VueRouter from 'vue-router'

// 引入组件
import Login from '@/views/login/Login.vue'
import Layout from '@/views/layout/Layout.vue'

Vue.use(VueRouter)

const routes = [
  // 登录
  {
    path: '/login',
    component: Login
  },
  // 后台首页
  {
    path: '/',
    component: Layout,
    redirect: '/home',
    meta: { path: "/home", title: "后台首页" },
    children: [
      {
        path: '/home',
        component: () => import('@/views/home/Home.vue')
      }
    ]
  },
  // 坐席管理
  {
    path: '/creatCompany',
    component: Layout,
    redirect: '/creatCompany/creat-company',
    meta: { path: "/creatCompany", title: "坐席管理" },
    children: [
      {
        path: '/creatCompany/creatCompany',
        component: () => import('@/views/creatCompany/creatCompany.vue'),
        meta: { path: "/creatCompany/creatCompany", title: "公司管理" },
      },
      {
        path: '/creatCompany/creatSeat',
        component: () => import('@/views/creatCompany/creatSeat.vue'),
        meta: { path: "/creatCompany/creatSeat", title: "坐席管理" },
      },
      {
        path: '/creatCompany/creatCompanyService',
        component: () => import('@/views/creatCompany/creatCompanyService.vue'),
        meta: { path: "/creatCompany/creatCompanyService", title: "公司管理--客服" },
      },
      {
        path: '/creatCompany/creatSeatService',
        component: () => import('@/views/creatCompany/creatSeatService.vue'),
        meta: { path: "/creatCompany/creatSeatService", title: "坐席管理--客服" },
      },
    ]
  },
  // 组织架构
  {
    path: '/structure',
    component: Layout,
    redirect: '/structure/department',
    meta: { path: "/structure", title: "组织架构" },
    children: [
      {
        path: '/structure/department',
        component: () => import('@/views/structure/department.vue'),
        meta: { path: "/structure/department", title: "部门管理" },
      },
      {
        path: '/structure/authority',
        component: () => import('@/views/structure/authority.vue'),
        meta: { path: "/structure/authority", title: "权限管理" },
      },
    ]
  },
  // 通话记录
  {
    path: '/callLog',
    component: Layout,
    meta: { path: "/callLog", title: "通话记录" },
    children: [
      {
        path: '',
        component: () => import('@/views/callLog/callLog.vue')
      },
    ]
  },
  // 财务管理
  {
    path: '/finance',
    component: Layout,
    redirect: '/finance/paylog',
    meta: { path: "/finance", title: "财务管理" },
    children: [
      {
        path: '/finance/paylog',
        component: () => import('@/views/finance/paylog.vue'),
        meta: { path: "/finance/paylog", title: "企业充值日志" },
      },
      {
        path: '/finance/changeLog',
        component: () => import('@/views/finance/changeLog.vue'),
        meta: { path: "/finance/changeLog", title: "坐席日志信息" },
      },
    ]
  },
  // 客服管理
  {
    path: '/service',
    component: Layout,
    redirect: '/service/pending',
    meta: { path: "/service", title: "客服管理" },
    children: [
      {
        path: '/service/pending',
        component: () => import('@/views/service/pending.vue'),
        meta: { path: "/service/pending", title: "待处理续费查询" },
      },
      {
        path: '/service/processed',
        component: () => import('@/views/service/processed.vue'),
        meta: { path: "/service/processed", title: "已处理续费查询" },
      },
      {
        path: '/service/practical',
        component: () => import('@/views/service/practical.vue'),
        meta: { path: "/service/practical", title: "查询实际续费金额" },
      },
      {
        path: '/service/lastMonth',
        component: () => import('@/views/service/lastMonth.vue'),
        meta: { path: "/service/lastMonth", title: "查询上月客服续费" },
      },
    ]
  },
  // 客户管理
  {
    path: '/client',
    component: Layout,
    redirect: '/client/monthReport',
    meta: { path: "/client", title: "客户管理" },
    children: [
      {
        path: '/client/encryptSwitch',
        component: () => import('@/views/client/encryptSwitch.vue'),
        meta: { path: "/client/encryptSwitch", title: "号码隐私开关" },
      },
      {
        path: '/client/clientNums',
        component: () => import('@/views/client/clientNums.vue'),
        meta: { path: "/client/clientNums", title: "客户列表" },
      },
      {
        path: '/client/creatNums',
        component: () => import('@/views/client/creatNums.vue'),
        meta: { path: "/client/creatNums", title: "个人私海" },
      },
      {
        path: '/client/departNums',
        component: () => import('@/views/client/departNums.vue'),
        meta: { path: "/client/departNums", title: "部门公海" },
      },
      {
        path: '/client/companyNums',
        component: () => import('@/views/client/companyNums.vue'),
        meta: { path: "/client/companyNums", title: "公司公海" },
      },
      {
        path: '/client/numberApply',
        component: () => import('@/views/client/numberApply.vue'),
        meta: { path: "/client/numberApply", title: "公海号码申请" },
      },
      {
        path: '/client/departmentNumberApply',
        component: () => import('@/views/client/departmentNumberApply.vue'),
        meta: { path: "/client/departmentNumberApply", title: "部门海号码申请" },
      },
      {
        path: '/client/evictionPolicy',
        component: () => import('@/views/client/evictionPolicy.vue'),
        meta: { path: "/client/evictionPolicy", title: "回收策略" },
      },
      {
        path: '/client/applyAudit',
        component: () => import('@/views/client/applyAudit.vue'),
        meta: { path: "/client/applyAudit", title: "申请号码审核" },
      },
      {
        path: '/client/applyAudit1',
        component: () => import('@/views/client/applyAudit1.vue'),
        meta: { path: "/client/applyAudit1", title: "申请号码审核--组长" },
      },
      {
        path: '/client/recycle',
        component: () => import('@/views/client/recycle.vue'),
        meta: { path: "/client/recycle", title: "回收站" },
      },
    ]
  },
  // 产品
  {
    path: '/product',
    component: Layout,
    meta: { path: "/product", title: "产品" },
    children: [
      {
        path: '',
        component: () => import('@/views/product/product.vue')
      },
    ]
  },
  // 呼叫任务
  {
    path: '/callMission',
    component: Layout,
    meta: { path: "/callMission", title: "呼叫任务" },
    children: [
      {
        path: '',
        component: () => import('@/views/callMission/callMission.vue')
      },
    ]
  },
  // 话务报表
  {
    path: '/report',
    component: Layout,
    redirect: '/report/realReport',
    meta: { path: "/report", title: "话务报表" },
    children: [
      {
        path: '/report/realReport',
        component: () => import('@/views/report/realReport.vue'),
        meta: { path: "/report/realReport", title: "实时报表" },
      },
      {
        path: '/report/dayReport',
        component: () => import('@/views/report/dayReport.vue'),
        meta: { path: "/report/dayReport", title: "日报表" },
      },
      {
        path: '/report/monthReport',
        component: () => import('@/views/report/monthReport.vue'),
        meta: { path: "/report/monthReport", title: "月报表" },
      },
    ]
  },
  // 统计视图
  {
    path: '/charts',
    component: Layout,
    redirect: '/charts/clientsCharts',
    meta: { path: "/charts", title: "统计视图" },
    children: [
      {
        path: '/charts/clientsCharts',
        component: () => import('@/views/charts/clientsCharts.vue'),
        meta: { path: "/charts/clientsCharts", title: "意向客户数据报表" },
      },
      {
        path: '/charts/clientsRanking',
        component: () => import('@/views/charts/clientsRanking.vue'),
        meta: { path: "/charts/clientsRanking", title: "意向客户排名" },
      },
      {
        path: '/charts/callRanking',
        component: () => import('@/views/charts/callRanking.vue'),
        meta: { path: "/charts/callRanking", title: "通话时长排名" },
      },
      {
        path: '/charts/performanceRanking',
        component: () => import('@/views/charts/performanceRanking.vue'),
        meta: { path: "/charts/performanceRanking", title: "业绩排名" },
      },
    ]
  },
  // 短信管理
  {
    path: '/note',
    component: Layout,
    redirect: '/note/note',
    meta: { path: "/note", title: "短信管理" },
    children: [
      {
        path: '/note/note',
        component: () => import('@/views/note/note.vue'),
        meta: { path: "/note/note", title: "短信" },
      },
      {
        path: '/note/taskulous',
        component: () => import('@/views/note/taskulous.vue'),
        meta: { path: "/note/taskulous", title: "短信提醒跟进" },
      },
      {
        path: '/note/blackList',
        component: () => import('@/views/note/blackList.vue'),
        meta: { path: "/note/blackList", title: "短信人员黑名单" },
      },
      // {
      //   path: '/note/note',
      //   component: () => import('@/views/note/note.vue'),
      //   meta: { path: "/note/note", title: "短信" },
      // },
      {
        path: '/note/massNoteAdd',
        component: () => import('@/views/note/massNoteAdd.vue'),
        meta: { path: "/note/massNoteAdd", title: "群发短信模板添加" },
      },
      {
        path: '/note/massNoteAudit',
        component: () => import('@/views/note/massNoteAudit.vue'),
        meta: { path: "/note/massNoteAudit", title: "群发短信内容审核" },
      },
      {
        path: '/note/hangNoteAdd',
        component: () => import('@/views/note/hangNoteAdd.vue'),
        meta: { path: "/note/hangNoteAdd", title: "挂机短信模板添加" },
      },
      {
        path: '/note/hangNoteAudit',
        component: () => import('@/views/note/hangNoteAudit.vue'),
        meta: { path: "/note/hangNoteAudit", title: "挂机短信内容审核" },
      },
    ]
  },
  // 知识库
  {
    path: '/repository',
    component: Layout,
    meta: { path: "/repository", title: "知识库" },
    children: [
      {
        path: '',
        component: () => import('@/views/repository/repository.vue')
      },
    ]
  },
  // 工单管理
  {
    path: '/workorder',
    component: Layout,
    meta: { path: "/workorder", title: "工单管理" },
    children: [
      {
        path: '',
        component: () => import('@/views/workorder/workorder.vue')
      },
    ]
  },
  // 审核管理
  {
    path: '/audit',
    component: Layout,
    redirect: '/audit/audit',
    meta: { path: "/audit", title: "审核管理" },
    children: [
      {
        path: '/audit/audit',
        component: () => import('@/views/audit/audit.vue'),
        meta: { path: "/audit/audit", title: "合同管理" },
      },
      {
        path: '/workorder/notCheckWorkorder',
        component: () => import('@/views/workorder/notCheckWorkorder.vue'),
        meta: { path: "/workorder/notCheckWorkorder", title: "待审批工单" },
      },
    ]
  },
  // sip
  // {
  //   path: '/sip',
  //   component: Layout,
  //   meta: { path: "/sip", title: "SIP呼叫" },
  //   children: [
  //     {
  //       path: '',
  //       component: () => import('@/views/sip/sip.vue')
  //     },
  //   ]
  // },
  // 取消标记
  {
    path: '/unmark',
    component: Layout,
    meta: { path: "/unmark", title: "取消标记" },
    children: [
      {
        path: '/',
        component: () => import('@/views/unmark/unmark.vue'),
      },
    ]
  },
]

const router = new VueRouter({
  routes
})

export default router
