import { Message } from 'element-ui'
const showMessage = Symbol('showMessage')

class ElMessage {
    success(options, single = true) {
        this[showMessage]('success', options, single)
    }
    warning(options, single = true) {
        this[showMessage]('warning', options, single)
    }
    info(options, single = true) {
        this[showMessage]('info', options, single)
    }
    error(options, single = true) {
        this[showMessage]('error', options, single)
    }
    /* eslint-disable */
    [showMessage](type, options, single) {
        console.log('message options:', options)
        if (single) {
            let doms = document.getElementsByClassName('el-message');
            //el-message__content
            let canShow = true;
            for (let i = 0; i < doms.length; i++) {
                if (options == doms[i].getElementsByClassName('el-message__content')[0].innerHTML) {
                    canShow = false;
                }
            }
            if (doms.length === 0 || canShow) {
                Message[type](options)
            }
        } else {
            Message[type](options)
        }
    }
}
export {
    ElMessage
}