/**
 * login
 * 登录
 */

import request from '@/utils/request'

// 登录
export const getLogin = (data) => {
    return request({
        method: 'post',
        url: '/login/in',
        data
    })
}
// 获取验证码
export const getCode = (data) => {
    return request({
        method: 'post',
        url: '/login/get_code',
        data
    })
}