<template>
  <div class="left-menu">
    <!-- logo -->
    <div class="logo-container">
      <!-- <img
        class="logo"
        width="30"
        height="30"
        src="../../assets/images/logo3.png"
        alt="logo"
      /> -->
      <!-- <span>乐呼云CRM</span> -->
      <div
        class="logo"
        style="
          color: #409eff;
          font-size: 18px;
          font-weight: bold;
          margin-left: 20px;
        "
      >
        <!-- <img src="../assets/img/LOGO.png" alt="" style="width:40px;height:40px;margin: -10px 0px;"> -->
        123呼 - 专业外呼
      </div>
      <!-- <div>
        商机
        <img src="../../assets/images/logo.png" alt="" width="30" height="30" />
        通信
      </div> -->
    </div>

    <!-- 导航 -->
    <el-menu
      background-color="#fff"
      text-color="#333"
      :default-active="$route.path"
      unique-opened
      router
    >
      <!-- 后台首页 -->
      <el-menu-item index="/home">
        <i class="iconfont el-icon-s-home"></i>
        <span slot="title">后台首页</span>
      </el-menu-item>

      <!-- 坐席管理 -->
      <el-submenu
        index="0"
        v-if="
          (roleName != '人员' && roleName != '组长') ||
          parentId == 7 ||
          parentId == 3555 ||
          parentId == 2
        "
      >
        <template slot="title">
          <i class="iconfont el-icon-s-platform"></i>
          <span>坐席管理</span>
        </template>
        <el-menu-item
          index="/creatCompany/creatCompany"
          v-if="roleName != '企业' && parentId != 7 && parentId != 2"
          >公司管理</el-menu-item
        >
        <el-menu-item
          index="/creatCompany/creatCompanyService"
          v-if="parentId == 2"
          >公司管理--内部</el-menu-item
        >
        <el-menu-item
          index="/creatCompany/creatSeat"
          v-if="parentId != 7 && parentId != 2"
          >坐席管理</el-menu-item
        >
        <el-menu-item
          index="/creatCompany/creatSeatService"
          v-if="parentId == 2"
          >坐席管理--内部</el-menu-item
        >
      </el-submenu>

      <!-- 组织架构 -->
      <el-submenu index="1" v-if="roleName == '企业'">
        <template slot="title">
          <i class="iconfont el-icon-s-check"></i>
          <span>组织架构</span>
        </template>
        <el-menu-item index="/structure/department">部门管理</el-menu-item>
        <el-menu-item index="/structure/authority">权限管理</el-menu-item>
      </el-submenu>

      <!-- 通话记录 -->
      <el-menu-item index="/callLog">
        <i class="iconfont el-icon-phone"></i>
        <span slot="title">通话记录</span>
      </el-menu-item>

      <!-- 财务管理 -->
      <el-submenu
        index="2"
        v-if="
          (roleName != '人员' && roleName != '组长') ||
          parentId == 3555 ||
          role == '客服' ||
          parentId == 2
        "
      >
        <template slot="title">
          <i class="iconfont el-icon-s-finance"></i>
          <span>财务管理</span>
        </template>
        <!-- <el-menu-item index="/finance/paylog" v-if="parentId == 2"
          >企业充值日志</el-menu-item
        > -->
        <el-menu-item index="/finance/changeLog">坐席日志信息</el-menu-item>
      </el-submenu>

      <!-- 客服管理 -->
      <el-submenu index="3" v-if="parentId == 2">
        <template slot="title">
          <i class="iconfont el-icon-s-custom"></i>
          <span>客服管理</span>
        </template>
        <el-menu-item index="/service/pending">待处理续费查询</el-menu-item>
        <el-menu-item index="/service/processed">已处理续费查询</el-menu-item>
        <el-menu-item index="/service/practical" v-if="roleName == '管理员'"
          >查询实际续费金额</el-menu-item
        >
        <el-menu-item index="/service/lastMonth" v-if="roleName == '管理员'"
          >查询上月客服续费</el-menu-item
        >
      </el-submenu>

      <!-- 客户管理 -->
      <el-submenu index="4" v-if="realname != 'admin'">
        <template slot="title">
          <i class="iconfont el-icon-s-custom"></i>
          <span>客户管理</span>
        </template>
        <el-menu-item
          index="/client/encryptSwitch"
          v-if="
            roleName != '管理员' && roleName != '组长' && roleName != '人员'
          "
          >号码隐私开关</el-menu-item
        >
        <el-menu-item index="/client/clientNums">客户列表</el-menu-item>
        <el-menu-item index="/client/creatNums" v-if="roleName != '管理员'"
          >个人私海</el-menu-item
        >
        <el-menu-item index="/client/departNums" v-if="roleName != '管理员'"
          >部门公海</el-menu-item
        >
        <el-menu-item index="/client/companyNums" v-if="roleName != '管理员'"
          >公司公海</el-menu-item
        >
        <el-menu-item
          index="/client/evictionPolicy"
          v-if="roleName != '组长' && roleName != '人员'"
          >回收策略</el-menu-item
        >
        <!-- <el-menu-item
          index="/client/numberApply"
          v-if="roleName != '管理员' && roleName != '企业'"
          >公海号码申请</el-menu-item
        >
        <el-menu-item
          index="/client/departmentNumberApply"
          v-if="roleName != '管理员' && roleName != '企业'"
          >部门海号码申请</el-menu-item
        > -->

        <el-menu-item
          index="/client/recycle"
          v-if="
            roleName != '管理员' && roleName != '组长' && roleName != '人员'
          "
          >回收站</el-menu-item
        >
      </el-submenu>

      <!-- 产品 -->
      <!-- <el-menu-item
        index="/product"
        v-if="roleName != '管理员' && roleName != '人员'"
      >
        <i class="iconfont el-icon-share"></i>
        <span slot="title">产品</span>
      </el-menu-item> -->

      <!-- 呼叫任务 -->
      <el-menu-item index="/callMission" v-if="roleName != '管理员'">
        <i class="iconfont el-icon-s-flag"></i>
        <span slot="title">呼叫任务</span>
      </el-menu-item>

      <!-- 话务报表 -->
      <el-submenu index="5">
        <template slot="title">
          <i class="iconfont el-icon-s-order"></i>
          <span>话务报表</span>
        </template>
        <el-menu-item index="/report/realReport" v-if="roleName != '管理员'"
          >实时报表</el-menu-item
        >
        <el-menu-item
          index="/report/dayReport"
          v-if="roleName != '企业' && roleName != '组长' && roleName != '人员'"
          >日报表</el-menu-item
        >
        <el-menu-item
          index="/report/monthReport"
          v-if="roleName != '企业' && roleName != '组长' && roleName != '人员'"
          >月报表</el-menu-item
        >
      </el-submenu>

      <!-- 短信管理 -->
      <!-- <el-submenu index="7">
        <template slot="title">
          <i class="iconfont el-icon-s-comment"></i>
          <span>短信管理</span>
        </template>
        <el-menu-item
          index="/note/note"
          v-if="roleName != '企业' && roleName != '组长' && roleName != '人员'"
          >短信</el-menu-item
        >
        <el-menu-item index="/note/taskulous" v-if="roleName != '管理员'"
          >短信提醒跟进</el-menu-item
        >
        <el-menu-item
          index="/note/blackList"
          v-if="
            roleName != '管理员' && roleName != '组长' && roleName != '人员'
          "
          >短信人员黑名单</el-menu-item
        >
        <el-menu-item
          index="/note/massNoteAdd"
          v-if="roleName != '管理员' && roleName != '企业'"
          >群发短信模板添加</el-menu-item
        >

        <el-menu-item
          index="/note/hangNoteAdd"
          v-if="roleName != '管理员' && roleName != '企业'"
          >挂机短信模板添加</el-menu-item
        >
      </el-submenu> -->

      <!-- 知识库 -->
      <el-menu-item index="/repository" v-if="roleName != '管理员'">
        <i class="iconfont el-icon-s-management"></i>
        <span slot="title">知识库</span>
      </el-menu-item>

      <!-- 工单管理 -->
      <!-- <el-menu-item index="/workorder" v-if="roleName != '管理员'">
        <i class="iconfont el-icon-s-promotion"></i>
        <span slot="title">工单管理</span>
      </el-menu-item> -->

      <!-- 审核管理 -->
      <!-- <el-submenu index="9">
        <template slot="title">
          <i class="iconfont el-icon-s-claim"></i>
          <el-badge is-dot class="item" v-if="hintMessage">审核管理</el-badge>
          <span v-else>审核管理</span>
        </template>
        <el-menu-item index="/workorder/notCheckWorkorder"
          >待审批工单</el-menu-item
        >
        <el-menu-item index="/audit/audit" v-if="roleName != '管理员'"
          >合同管理</el-menu-item
        >
        <el-menu-item
          index="/client/applyAudit"
          v-if="
            roleName != '管理员' && roleName != '人员' && roleName != '组长'
          "
          >申请号码审核</el-menu-item
        >
        <el-menu-item index="/client/applyAudit1" v-if="roleName == '组长'"
          >申请号码审核--组长</el-menu-item
        >
        <el-menu-item
          index="/note/massNoteAudit"
          v-if="
            (roleName != '企业' && roleName != '组长' && roleName != '人员') ||
            parentId == 3555 ||
            role == '客服'
          "
          >群发短信内容审核</el-menu-item
        >
        <el-menu-item
          index="/note/hangNoteAudit"
          v-if="
            (roleName != '企业' && roleName != '组长' && roleName != '人员') ||
            parentId == 3555 ||
            role == '客服'
          "
          >挂机短信内容审核</el-menu-item
        >
      </el-submenu> -->

      <!-- 取消标记 -->
      <el-menu-item index="/unmark">
        <i class="iconfont el-icon-warning"></i>
        <span slot="title">取消标记</span>
      </el-menu-item>
    </el-menu>
  </div>
</template>

<script>
import { workOrderRemindWorkType } from "@/api/workOrder";

export default {
  data() {
    return {
      role: null,
      roleName: null,
      realname: null,
      parentId: null,
      hintMessage: "",
    };
  },
  created() {
    this.realname = localStorage.getItem("realname");
    this.roleName = localStorage.getItem("roleName");
    this.parentId = localStorage.getItem("parentId");
    this.role = localStorage.getItem("role");
  },
  mounted() {
    this.hintList();
  },
  methods: {
    // 待审核工单提示
    hintList() {
      let params = {};
      let res = workOrderRemindWorkType(params);
      res.then((res) => {
        this.hintMessage = res.data.data;
      });
    },
  },
};
</script>

<style lang="less" scoped>
// @keyframes turn {
//   0% {
//     -webkit-transform: rotate(0deg);
//   }
//   25% {
//     -webkit-transform: rotate(90deg);
//   }
//   50% {
//     -webkit-transform: rotate(180deg);
//   }
//   75% {
//     -webkit-transform: rotate(270deg);
//   }
//   100% {
//     -webkit-transform: rotate(360deg);
//   }
// }

.w230 {
  min-width: 230px;
}
.left-menu {
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.4);
  /*隐藏滚动条，当IE下溢出，仍然可以滚动*/
  -ms-overflow-style: none;
  /*火狐下隐藏滚动条*/
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  // width: 230px;
  background-color: #fff;
  padding: 20px;
  box-sizing: border-box;
  .logo-container {
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(191, 203, 217);
    .logo {
      cursor: pointer;
      margin-right: 5px;
      margin-bottom: 10px;
      animation: turn 0.03s linear infinite;
    }
  }
  .el-menu {
    & > .is-active > .el-submenu__title {
      .iconfont,
      span {
        color: #1F7EFE !important;
      }
    }
    border-right: 0px;
    color: red;
    .el-menu-item {
      color: #333;
      .iconfont {
        margin-right: 10px;
      }
    }
    .is-active {
      color: #fff !important;
      border-radius: 10px;
      background-color: #1F7EFE !important;
      background-size: 300px 100px;
    }
    .is-active:corner-present {
      color: red;
    }
    .el-submenu {
      .el-submenu__title {
        .iconfont {
          margin-right: 10px;
        }
      }
      .el-menu-item {
        &:hover {
          color: #fff !important;
          border-radius: 15px;
          background-color: #1F7EFE !important;
        }
      }
    }
  }
  /deep/.el-badge__content.is-fixed.is-dot {
    right: 0;
  }
  /deep/.el-badge__content.is-fixed {
    top: 20px;
  }
}
</style>
